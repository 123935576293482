import React from "react";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";
import "./PetParentInfo.css"

const PetParentInfo = ({parentInfo, address, mailingAddress, hidePassword, policyEffectiveDate, status}) => {
    return (
        <div className="d-md-flex flex-column justify-content-between" >
            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" >Pet Parent(s)</span>
                    <Link to={AppLinks.account.changeProfile} className="text-secondary" >Edit</Link>
                </div>
                <span> {parentInfo.FirstName} {parentInfo.LastName}</span> <br />
                <span> {parentInfo.PrimaryPhone} </span><br />
                <span> {parentInfo.Email} </span>
            </div>
            {!hidePassword &&
                <div className="mt-3">
                    <div className="d-flex flex-row justify-content-between" >
                        <span className="heading" >Password</span>
                    <Link to={AppLinks.account.changePassword} className="text-secondary" >Edit</Link>
                    {/* <Link to={AppLinks.account.changePassword} className="d-block d-lg-none" ><button className="btn btn-outline-secondary pt-0 pb-0">Edit</button></Link> */}
                    </div>
                    <span> ****** </span> <br />
                </div>
            }

            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" >Physical Address</span>
                    { (status.toLowerCase().trim() === "cancel prorata" || 
                        status.toLowerCase().trim() === "cancel full") ? null :<Link to={AppLinks.account.changePhysicalAddress} className="text-secondary" >Edit</Link>}
                    {/* { status.toLowerCase().trim() === "cancel prorata" ? null :<Link to={AppLinks.account.changePhysicalAddress} className="d-block d-lg-none" ><button className="btn btn-outline-secondary pt-0 pb-0">Edit</button></Link>} */}
                </div>
                <span> {address.Street} </span> <br />
                <span> {address.City}, {address.State} {address.PostalCode} </span>
            </div>

            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" >Mailing Address <br/>for reimbursement checks</span>    
                    <Link to={AppLinks.account.updateReimbursement} className="text-secondary" >Edit</Link>                
                    {/* <Link to={AppLinks.account.updateReimbursement} className="d-block d-lg-none" ><button className="btn btn-outline-secondary pt-0 pb-0">Edit</button></Link> */}
                    </div>
                    
                    <span> {mailingAddress.Street} </span> <br />
                    <span> {mailingAddress.City}, {mailingAddress.State} {mailingAddress.PostalCode} </span>
               
            </div>
            <div className="mt-3 d-flex consent-text">
                <div className="mr-1 text-primary"> <i className="fa fa-check-square-o"></i> </div>
                <div className="consent-text">Consent to <a href={AppLinks.externalLinks.legal} className="tos-privacy-link" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href={AppLinks.externalLinks.privacyPolicy} className="tos-privacy-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a> provided on {new Date(policyEffectiveDate).toLocaleDateString('en-US')}.</div>
            </div>
        </div>
    )
}

export default PetParentInfo;