import React from "react";
import { Link } from "react-router-dom";
import { mobileMenuItems } from "../../menu";
import injectExtole from "../../../extole-hoc/extole-hoc";

import "./MobileMenu.css";

const MobileMenu = props => {
  const homeLinkStyle = { borderTop: "2px solid #E4E4E4" };
  var newMobileMenuItems =  mobileMenuItems;
  let menuToHideForCancelledAccounts = ["View Policy Options","Change Physical Address","Update Billing Info","Add a Pet","Cancel Account"];
  if(props.userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
    props.userAccountDetails.Status.toLowerCase().trim() === "cancel full") {
    newMobileMenuItems = mobileMenuItems?.map((element) => {
       return {...element, menu_items: element.menu_items?.filter(x=>!menuToHideForCancelledAccounts.includes(x.subtitle))}
    })
  }

  return (
    <div id="mobile-side-menu" className="mobile-menu">
      <div id="mobile-sidedrawer-welcome" className="mobile-sidebar-welcome">
        <p>Hi <strong>{props.userName}</strong> !</p>
        <p>Welcome to the Customer Center!</p>
      </div>
      <div>
        {newMobileMenuItems.map(
          ({ header, identifier, link, menu_items, index }) =>
            menu_items === undefined ? (
              <Link to={link} key={identifier}>
                <div
                  onClick={props.menuHeaderClick}
                  style={identifier === "home" ? { homeLinkStyle } : {}}
                  id={identifier}
                  className="side-drawer-header d-flex align-items-center justify-content-between"
                >
                  <div>
                    {identifier === "home" && (
                      <img
                        id="paws-icon-home"
                        alt="paws-icon-home"
                        src="../../assets/images/icon_paw_mobileMenu.png"
                      />
                    )}
                    <span style={{ fontWeight: 400 }}> {header} </span>
                  </div>
                  {identifier === "home" && (
                    <span>
                      <i className="sidedrawer-arrow fa fa-angle-right" />
                    </span>
                  )}
                </div>
              </Link>
            ) : (
              <div 
                key={identifier + index} 
                onClick={props.menuHeaderClick}
                className={identifier === "refer-friend" && !props.enableRAF ? "d-none" : ""}
              >
                <div className="side-drawer-header active-header ">
                  <span> {header} </span>
                  <span className="pull-right">
                    <i className="sidedrawer-arrow fa " />
                  </span>
                </div>
                <div id={identifier}>
                  <ul className="mobile-sidedrawer-submenu-active">
                    {menu_items.map(({ subtitle, link, external }) => {
                      return external ? (
                        <a
                          href={link}
                          key={subtitle}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li
                            onClick={props.menuHeaderClick}
                            className="d-flex align-items-center justify-content-between"
                          >
                            {subtitle}
                            <span>
                              <i className="sidedrawer-arrow fa fa-angle-right" />
                            </span>
                          </li>
                        </a>
                      ) : (
                        <Link to={link} key={subtitle}>
                          <li
                            onClick={props.menuHeaderClick}
                            className="d-flex align-items-center justify-content-between"
                          >
                            {subtitle}
                            <span>
                              <i className="sidedrawer-arrow fa fa-angle-right" />
                            </span>
                          </li>
                        </Link>
                      );
                    })
                    }
                  </ul>
                </div>
              </div>
            )
        )}
      </div>
      <div className="bottom-spacer" />
    </div>
  );
};

export default injectExtole(MobileMenu, { ctaID: "refer-friend" });
