import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../common/loader/Loader";
import { Redirect } from "react-router";
import {
    checkCanUserEdit,
    updateUserAccountInfo
} from "../../common/util/AccountsUtil";
import { getAlertsByAccount } from "./../../../store/actions/alerts-actions";
import ChangePolicyOptions from "./ChangePolicyOptions.component";
import ChangePolicyConfirmation from "./ChangePolicyConfirmation.component";
import CancelNoChangePolicy from "./CancelNoChangePolicy.component";
import ErrorView from "../../common/error-view/ErrorView";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";

import { getAccountInfo, setAccountDataRefresh } from "../../../store/actions/accounts-actions";
import { getAvailablePolicyOptions } from "../../../store/actions/pets-actions";
import { setNewRoute } from "../../../store/actions/alerts-actions";

import { AppLinks } from "../../../constants/app-links";
import { store } from "./../../../index";
class ChangePolicy extends Component {
  state = {
    policyChangeSuccess: false,
    newPolicyDetails: null,
    newTotalMonthlyPremium: 0
  };

  

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.actions.getAvailablePolicyOptions();

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
      });

  }

    onChangePolicySubmitted = (changedPolicyDetails, monthlyPremium) => {
    //    this.setState({    
    //  policyChangeSuccess: true,
    //  newPolicyDetails: changedPolicyDetails,
    //  newTotalMonthlyPremium: monthlyPremium
    //});    
    //this.props.actions.setNewRoute("/ChangePolicyOptions/Step2");
    //For google analytics
   /* window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname + "/Confirm"
      });*/
        //alert(process.env.REACT_APP_UPDATE_API_DELAY);

            //store.dispatch(setRetryBillingStatus(true));
            store.dispatch(setAccountDataRefresh());
            //store.dispatch(getAccountInfo());

            //store.dispatch(getAlertsByAccount());


        setTimeout(() => {

            this.setState({
                policyChangeSuccess: true,
                newPolicyDetails: changedPolicyDetails,
                newTotalMonthlyPremium: monthlyPremium
            });

            //For google analytics
            window.dataLayer.push({
                'event': 'Pageview',
                'url': window.location.pathname + '/Confirm'
            });
            this.props.actions.setNewRoute("/ChangePolicyOptions/Step2");
            //alert('complete');
        }, 500);// process.env.REACT_APP_UPDATE_API_DELAY);

      window.scrollTo(0, 0);
        //console.log("***************");
        //this.props.userAccountDetails.newTotalMonthlyPremium = monthlyPremium;
        //console.log(this.props.userAccountDetails.newTotalMonthlyPremium);
        //this.props.userAccountDetails.actions.state.updateUserAccountInfo(this.props.userAccountDetails);
  };

  render() {
    const {
      petDetails,
      fetchingPets,
      fetchingAvailableOptions,
      error,
      availablePolicyOptions,
      availablePolicyOptionsError
    } = this.props;
    const { policyChangeSuccess } = this.state;

    var today = new Date().getTime();
    var inAnniversaryNoticeWindow = false;
    var inAnniversaryPeriod = false;
    var annualRepriceChangeScheduled = this.props.userAccountDetails.HasPendingAnniversaryChange;
    var anniversaryDate = new Date(this.props.userAccountDetails.NextAnniversaryDate).getTime();

    if (new Date(this.props.userAccountDetails.NextAnniversaryDate).getTime() ==
        new Date(this.props.userAccountDetails.NextBillingDate).getTime() + (24 * 60 * 60 * 1000)) {
        inAnniversaryPeriod = true;
    }

    if (today + (this.props.userAccountDetails.DaysAnniversaryNotification * 24 * 60 * 60 * 1000) >
        (anniversaryDate - (24 * 60 * 60 * 1000)) && today < anniversaryDate - (24 * 60 * 60 * 1000)) {
        inAnniversaryNoticeWindow = true;
    }

    if (annualRepriceChangeScheduled && inAnniversaryNoticeWindow && !inAnniversaryPeriod) {
      return (
        <Redirect to={AppLinks.policy.anniversaryCoverageOptions} />
      );
    } else {

      return (
        <div style={{ position: this.props.canUserEdit ? "static" : "relative" }}>
          {this.props.canUserEdit ? null : <div className="disable-user-edit" />}
          {this.props.userAccountDetails.Status == "Cancel Prorata" || 
            this.props.userAccountDetails.Status == "Cancel Full" ? (
              <CancelNoChangePolicy
                backToHome={() => {
                  this.props.history.push("/");
                }}
              />
            ) : 
            ( fetchingPets || fetchingAvailableOptions ? 
              (
              <div style={{ height: 300 }}>
                <Loader />
              </div>
              ) : (
              <React.Fragment>
                {petDetails &&
                  petDetails.length > 0 &&
                  !error &&
                  !availablePolicyOptionsError &&
                  availablePolicyOptions ? (
                  <React.Fragment>
                    {policyChangeSuccess ? (
                      <UpdateSuccessView
                        statusHeader={<div className="d-none d-md-inline-block">Your policy options has been updated.</div>}
                        title={"Your new Policy"}
                      >
                        <ChangePolicyConfirmation
                          newPolicyDetails={this.state.newPolicyDetails}
                          newTotalMonthlyPremium={this.state.newTotalMonthlyPremium}
                        />
                      </UpdateSuccessView>
                    ) : (
                      <ChangePolicyOptions
                        getAlertsByAccount={this.props.actions.getAlertsByAccount}
                        petDetails={petDetails}
                        availablePolicies={
                          availablePolicyOptions.available_policies
                        }
                        policyEffectiveDate={availablePolicyOptions.pending_effective_date}
                        onPolicyChangeSuccess={this.onChangePolicySubmitted}
                        userAccountDetails = {this.props.userAccountDetails}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <div className="container" style={{ height: 300 }}>
                    <h1 className="text-primary mb-3 mb-lg-0">Policy Options</h1>
                    {
                      (error || availablePolicyOptionsError) ?
                        <ErrorView>Unable to fetch policy options. Please try later.</ErrorView> :
                        <p>No pets available to show you right now. Please try later.</p>
                    }
                  </div>
                )
                }
              </React.Fragment>
              )
            )
          }
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  petDetails: state.pets.petsDetails.filter(pet => pet.Status === "Active"),
  userAccountDetails: state.account.userAccountDetails,
  canUserEdit: state.account.canUserEdit,
  error: state.pets.error,
  userAccountDetails: state.account.userAccountDetails,
  userAccountDetailsFetching: state.account.fetching,
  availablePolicyOptions: state.pets.availablePolicyOptions,
  fetchingAvailableOptions: state.pets.fetchingAvailableOptions,
  fetchingPets: state.pets.fetchingPets,
  availablePolicyOptionsError: state.pets.availablePolicyOptionsError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
      { setAccountDataRefresh, getAccountInfo, getAvailablePolicyOptions, setNewRoute, getAlertsByAccount },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePolicy);
