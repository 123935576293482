import React, { Component } from "react";
import "./CancelNoChangeAddress.component.css";
import { Link } from "react-router-dom";
import { AppLinks } from "../../constants/app-links";

class CancelNoChangeAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return(
            <div className="container no-enrollments">
                <div className="row">
                    <div className="d-none d-sm-block col-6 col-sm-5 col-lg-4 px-0">
                        <img
                            className="img-fluid no-enrollments-image"
                            src="../assets/images/no-enrollments.jpg"
                            alt="worth-the-wait"
                        />
                        </div>
                    <div className="col col-sm-7 col-lg-8">
                        <h1 className="text-primary mb-3 mb-lg-0 sorry">
                            Sorry
                        </h1>
                        <p>
                        We are unable to change your physical address on your current account as it is pending cancellation. We apologize for the inconvenience. Please <Link to={AppLinks.others.contact}>click here</Link> to contact us with any questions.
                        </p>
                        <button
                            className="button-primary back-to-home"
                            type="submit"
                            onClick={this.props.backToHome}
                            >
                            Home
                            </button>
                    </div>
                </div>
            </div>
        );
    }
}
  
export default CancelNoChangeAddress;