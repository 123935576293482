import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPetsByAccount } from "../../../store/actions/pets-actions";
import {
  setAccountDataRefresh,
  setRetryBillingStatus,
  getAccountInfo
} from "../../../store/actions/accounts-actions";
import {
  getAlertsByAccount,
  setNewRoute
} from "../../../store/actions/alerts-actions";
import { log } from "../../../api/logging-api";
import MobileAppUpdateBillingView from "./MobileAppUpdateBillingView.components";

import { updateBilling, createNewBilling, pullBillingInfoFromAuthorize } from "../../../api/accounts-api";
import StateList from "../../common/data/StateList";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";
import MobileAppCancelNoUpdateBilling from "./MobileAppCancelNoUpdateBilling.component";
import MobileUpdateSuccessView from "../../common/update-success-view/MobileUpdateSuccessView";

import { AppLinks } from "../../../constants/app-links";

import Util from "../../common/util/util";
import { parseQueryString } from "../../common/util/util";

import ModalDialog from "../../common/modal-dialog/ModalDialog";

import "./MobileAppUpdateBilling.css";
import { store } from "./../../../index";

class MobileAppUpdateBilling extends Component {
  constructor(props) {
    super(props);
    this.errorText = null;
    this.state = {
      updateBillingSuccess: false,
      serverError: false,
      showSecurityDialog: false,
      showCvvDialog: false,
      sendingToServer: false,
      showUpdateMailingAddress: true,
      inMobileAppWebView: false,
    };
    
    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
    });
  }

  componentDidMount() {

    log({
      "level": "Information",
      "message": "Loaded UpdateBilling page",
    });
  
    window.scrollTo(0, 0);
     
    //attach street address listener
    window.initAddressAssistant(document);

    this.props.actions.getPetsByAccount();

    if (window.location.href.toLowerCase().indexOf("mobileappupdatebillinginfo") > -1) {
      this.setState({
        showUpdateMailingAddress: false,
        inMobileAppWebView: true,
      });
    }
  }

  toggleSecurityDialog = () => {
    this.setState({
      showSecurityDialog: !this.state.showSecurityDialog
    });
  };

  toggleCvvDialog = () => {
    this.setState({
      showCvvDialog: !this.state.showCvvDialog
    });
  };

  
  updateBilling = (accountId, email, values) => {
    //get billing info from addressFinder DOM selection
    let parentDomBillingAddress = window.getBillingAddressHelper(document);
    values.streetAddress = parentDomBillingAddress;
    var self = this;
    
    this.setState({
      sendingToServer: true
    });

    if (values.state === StateList.selectYourState) {
      this.errorText = "Please Select a Valid State";
      this.setState({
        serverError: true,
        sendingToServer: false
      });
      window.scrollTo(0, 0);
      return;
    }

    var tempCreditCardExpiryDate, tempMicroformPaymentToken;
    if (values.creditCardExpiryDate && values.microformPaymentToken) {
      tempMicroformPaymentToken = values.microformPaymentToken;
      tempCreditCardExpiryDate = values.creditCardExpiryDate.replace(/\//g, "-")
    }

    updateBilling(accountId, email, {
      MicroformPaymentToken: tempMicroformPaymentToken,
      CreditExpireDate: tempCreditCardExpiryDate,
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Street: values.streetAddress.trim(),
      City: values.city.trim(),
      State: values.state,
      PostalCode: values.zipCode,
      Country: "USA"
    })
      .then(response => {

        if (response.data.ErrorCode < 0) {

          log({
            "level": "Error",
            "message": "Error in response from updateBilling: " + response.data.ErrorMessage,
          });
  
          this.setState({
            sendingToServer: false,
            serverError: true
          });
          window.scrollTo(0, 0);
        } else {
            
          setTimeout(() => {

            store.dispatch(setRetryBillingStatus(true));
            store.dispatch(setAccountDataRefresh());
            store.dispatch(getAlertsByAccount());
            
            //For google analytics
            window.dataLayer.push({
              'event': 'Pageview',
              'url': window.location.pathname + '/Confirm'
            });
            
            self.setState({
              updateBillingSuccess : true
            });
          }, process.env.REACT_APP_UPDATE_API_DELAY);

        }
      })
      .catch(error => {
        this.errorText = error && error.errorMessage ? error.errorMessage : "";
        this.setState({
          sendingToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
      });
  };
  

  render() {
    var petDetailsText = "";

    if (this.state.updateBillingSuccess) {
      petDetailsText =
        this.props.petDetails && this.props.petDetails.length > 0
          ? Util.getCommaSeperatedNameString(
            this.props.petDetails.map(pet => {
              return pet.Name;
            })
          )
          : "your pets ";
    }

    return (
      <React.Fragment>
        {this.securityDialog()}
        {this.cvvDialog()}
        {this.props.userAccountDetails.Status == "Cancel Prorata" || 
          this.props.userAccountDetails.Status == "Cancel Full" ? (
            <MobileAppCancelNoUpdateBilling
              backToHome={() => {
                this.props.history.push("/");
              }}
            />
          ) : (this.state.updateBillingSuccess ? 
            (
              this.state.inMobileAppWebView ? (
                <MobileUpdateSuccessView
                    statusHeader=""
                    title="Your policy is active."
                    description={
                        "We look forward to protecting " +
                        petDetailsText +
                        "for years to come."
                    }
                    backToHome={() => {
                        this.props.history.push("/");
                    }}
                />
              ) : (
                <UpdateSuccessView
                  statusHeader="Your billing information has been updated."
                  title="Your policy is active."
                  description={
                    "We look forward to protecting " +
                    petDetailsText +
                    "for years to come."
                  }
                  backToHome={() => {
                    this.props.history.push("/");
                  }}
                />
              )
            ) : (
              <MobileAppUpdateBillingView
                userAccountDetails={this.props.userAccountDetails}
                userAccountDetailsFetching={this.props.userAccountDetailsFetching}
                userAccountFetchError={this.props.userAccountFetchError}
                AuthorizePaymentProfileId={this.props.userAccountDetails.AuthorizePaymentProfileId}
                canUserEdit={this.props.canUserEdit}
                errorText={this.errorText}
                sendingToServer={this.state.sendingToServer}
                serverError={this.state.serverError}
                showUpdateMailingAddress={this.state.showUpdateMailingAddress}
                toggleSecurityDialog={this.toggleSecurityDialog}
                toggleCvvDialog={this.toggleCvvDialog}
                updateBilling={this.updateBilling.bind(this)}
                backToHome={() => {
                  this.props.history.push("/");
                }}
              />
            )
          )
        }
      </React.Fragment>
    );
  }

  securityDialog = () => {
    return (
      <ModalDialog
        isOpen={this.state.showSecurityDialog}
        toggle={this.toggleSecurityDialog}
        modalHeader={"Online Protection"}
        modalBody={
          <div className="d-flex flex-column">
            We provide a safe and secure online enrollment experience and protect the
            privacy of your personal information. Your payment is secured by Cybersource
            and therefore, we're unable to process your payment information over the phone.
            <div className="d-flex flex-row align-items-center image-conatiner">
              <a
                href={AppLinks.externalLinks.bbb}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="accredited-image"
                  src="../../assets/images/accredited_1.png"
                  alt="accredited"
                />
              </a>
            </div>
          </div>
        }
        modalFooter={
          <button
            type="submit"
            className="button-primary-plus close-button"
            onClick={this.toggleSecurityDialog}
          >
            Close
          </button>
        }
        className="modal-popup"
      />
    );
  };

  cvvDialog = () => {
    return (
      <ModalDialog
        isOpen={this.state.showCvvDialog}
        toggle={this.toggleCvvDialog}
        modalHeader={"CVV2 (CVV, CSV) Number"}
        modalBody={
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              <img
                className="cvv-1-image"
                src="../../assets/images/cvv_1.png"
                alt="cvv1"
              />
              <img
                className="cvv-2-image "
                src="../../assets/images/cvv_2.png"
                alt="cvv2"
              />
            </div>
            On Visa, MasterCard and Discover cards, the CVV2 value is the last 3
            digit number located in the signature area on the back of your
            credit card, just to the right of your account number.
            <br />
            <br />
            On American Express cards, the CVV2 value is the 4 digit number
            located on the front of your credit card, just above and to the
            right of your account number.
          </div>
        }
        modalFooter={
          <button
            className="button-primary-plus close-button"
            type="submit"
            onClick={this.toggleCvvDialog}
          >
            Close
          </button>
        }
      />
    );
  };
}

// TODO: Move this stuff into modules/common/authorize-net
window.CommunicationHandler = {};

window.CommunicationHandler.onReceiveCommunication = function (argument) {

  let params = parseQueryString(argument.qstr);
  log({
    "level": "Information",
    "message": "onReceiveCommunication was called - params['action']: " + params['action'],
  });

  log({
    "level": "Information",
    "message": "params['response']: " + params['response'],
  });

  const state = store.getState();

  console.log(params);

  switch (params['action']) {
    case "resizeWindow":
      // frame.outerHeight(parseInt(params['height']));
      // let el = ReactDOM.findDOMNode(this.refs.edit_payment);
      // console.log("el = " + el);
      /*
      if (parseInt(params["width"]) > 0) {
        var w = parseInt(params["width"]);
        var h = parseInt(params["height"]);
        var ifrm = document.getElementById("edit_payment");
        ifrm.style.width = w.toString() + "px";
        ifrm.style.height = h.toString() + "px";
      }
      */

      break;
    case "successfulSave":
      log({
        "level": "Information",
        "message": "successfulSave from UpdateBilling - params:" + params,
      });

      // call the api to pull the updated billing info from Authorize.Net
      pullBillingInfoFromAuthorize(state.account.userAccountDetails.AccountID, state.account.userAccountDetails.PetParentInfo.Email)
          .then(response => {
     
          if (response.data.indexOf("Error") === -1) {
            if (window.location.href.toLowerCase().indexOf("mobileappupdatebillinginfo") > -1) {
              setTimeout(function () {
                store.dispatch(setRetryBillingStatus(true));
                store.dispatch(setAccountDataRefresh());
                store.dispatch(getAlertsByAccount());
  
                window.location = '/mobileappupdatebillinginfosuccess';
              }, 3000);
  
            } else {
              setTimeout(function () {
                store.dispatch(setRetryBillingStatus(true));
                store.dispatch(setAccountDataRefresh());
                store.dispatch(getAlertsByAccount());

                window.location = '/updatebillinginfosuccess';
              }, 3000);
            }
          } else {
             
            log({
              "level": "Error",
              "message": "pullBillingInfoFromAuthorize got error from Authorize.Net: " + response 
            })
          }
        })
        .catch(error =>
          log({
            "level": "Error",
            "message": "pullBillingInfoFromAuthorize failed - " + error.name + ": " + error.message
          })
        );

      break;
      case "cancel":
          
      if (window.location.href.toLowerCase().indexOf("mobileappupdatebillinginfo") > -1) {
      } else {
        window.location = '/';
      }
      break;
      case "transactResponse":
          var transactionResponse = JSON.parse(params["response"]);

          log({
              "level": "Information",
              "message": "transactResponse from UpdateBilling - transactionResponse:" + transactionResponse,
          });

          createNewBilling(state.account.userAccountDetails.AccountID,
              state.account.userAccountDetails.PastDueAmount.substring(1),
              transactionResponse.transId)
              .then(response => {
 
                  
                  if (window.location.href.toLowerCase().indexOf("mobileappupdatebillinginfo") > -1) {
                     
                      setTimeout(function () {
                          store.dispatch(setRetryBillingStatus(true));
                          store.dispatch(setAccountDataRefresh());
                          store.dispatch(getAlertsByAccount());

                          window.location = '/mobileappupdatebillinginfosuccess';
                      }, 3000);

                  } else {
                       
                      setTimeout(function () {
                          store.dispatch(setRetryBillingStatus(true));
                          store.dispatch(setAccountDataRefresh());
                          store.dispatch(getAlertsByAccount());

                          window.location = '/updatebillinginfosuccess';
                      }, 3000);
                  }
              
                  
              })
              .catch(error =>  
                 
          log({
            "level": "Error",
            "message": "createNewBilling failed - " + error.name + ": " + error.message
          })
               
        );

      break;
    default:
      break;
  }
}

const mapStateToProps = state => ({
  petDetails: state.pets.petsDetails.filter(pet => pet.Status === "Active"),
  userAccountDetails: state.account.userAccountDetails,
  canUserEdit: state.account.canUserEdit,
  userAccountDetailsFetching: state.account.fetchingAccount,
  userAccountFetchError: state.account.error
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAccountInfo,
      getPetsByAccount,
      setAccountDataRefresh,
      getAlertsByAccount,
      setNewRoute,
      log,
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileAppUpdateBilling);
