import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import PetParentInfo from "./components/PetParentInfo";
import BillingInfo from "./components/BillingInfo";
import ViewClaimStatusInfo from "./components/view-claim-status/ViewClaimStatusInfo";
import LiveChat from "../common/live-chat/LiveChat.js";
import { AppLinks } from '../../constants/app-links';

const Home = ({ userDetails, claimsDetails, enableRAF }) => {
    return (
        <div className="container-fluid">
            <div className="d-md-block container home" >
                <div className="links d-flex flex-row row flex-wrap" >
                    <div className="col-md-4 mb-4 list-container" >
                        <Link to={AppLinks.claims.fileClaim} >
                            <div className="home-list-box" >
                                <div><img className="home-list-icons claim-icon" src="/assets/images/icon-file-claim.svg" alt="File a Claim" /></div>
                                <div className="home-list-title col-9" >File a Claim</div>
                                <div><i className="fa fa-angle-right fa-2x text-secondary-angle " /></div>
                            </div>
                        </Link>
                    </div>
                    
                    { (userDetails.Status.toLowerCase().trim() === "cancel prorata" ||
                        userDetails.Status.toLowerCase().trim() === "cancel full")?null:(<div className="col-md-4 d-none d-md-block mb-4 list-container">
                        <Link to={AppLinks.account.updateBillingInfo} >
                            <div className="home-list-box">
                                <div><i className="fa fa-credit-card home-list-fa-icons" /></div>
                                <div className="home-list-title col-9" >Update Billing Info</div>
                                <div><i className="fa fa-angle-right fa-2x text-secondary-angle " /></div>
                            </div>
                        </Link>
                    </div>)}

                    <div className="col-md-4 mb-4 d-none d-md-block list-container" >
                        <Link to={AppLinks.claims.uploadMedicalRecords} >
                            <div className="home-list-box" >
                                <div><img className="home-list-icons" src="/assets/images/icon-upload-mr.svg" alt="Upload Medical Records" /></div>
                                <div className="home-list-title col-9" >Upload Medical Records</div>
                                <div><i className="fa fa-angle-right fa-2x text-secondary-angle " /></div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-4 mb-4 d-none d-md-block list-container" >
                        <Link to={AppLinks.claims.claimStatus} >
                            <div className="home-list-box" >
                                <div><img className="home-list-icons claim-icon" src="/assets/images/icon-view-claim.svg" alt="View Claim Status" /></div>
                                <div className="home-list-title col-9" >View Claim Status</div>
                                <div><i className="fa fa-angle-right fa-2x text-secondary-angle " /></div>
                            </div>
                        </Link>
                    </div>

                    { (userDetails.Status.toLowerCase().trim() === "cancel prorata" ||
                        userDetails.Status.toLowerCase().trim() === "cancel full")?null:(<div className="col-md-4 mb-4 d-none d-md-block list-container">
                        <Link to={AppLinks.policy.addPet} >
                            <div className="home-list-box">
                                <div><img className="home-list-icons" src="/assets/images/icon-add-a-pet.svg" alt="Add a pet" /></div>
                                <div className="home-list-title col-9" >Add a Pet</div>
                                <div><i className="fa fa-angle-right fa-2x text-secondary-angle " /></div>
                            </div>
                        </Link>
                    </div>)}

                        {/* <div className="col-md-6 col-lg-4 mb-4 list-container" >
                            {enableRAF &&
                                <div className="home-list-box" id="extole-home-refer">
                                    <div><img className="home-list-icons" src="/assets/images/icon_raf.svg" alt="Refer a Friend" /></div>
                                    <div className="home-list-title col-10" >Refer a Friend & Donate $25</div>
                                    <div><i className="fa fa-angle-right fa-2x text-secondary " /></div>
                                </div>}
                        </div> */}
                        { (userDetails.Status.toLowerCase().trim() === "cancel prorata" ||
                        userDetails.Status.toLowerCase().trim() === "cancel full")?(<div className="col-md-4 mb-4 d-none d-md-block list-container"></div>): null}

                </div>

                <div className="links row d-flex flex-row flex-wrap justify-content-around" >
                    <div className="d-flex flex-column justify-content-between col-md-4 mb-4 order-3 order-md-1">
                        <div className="content-wrapper">
                            <h2 className="text-primary-heading mb-0" > Pet Parent Information </h2>
                                { userDetails && userDetails.PetParentInfo &&
                                    <PetParentInfo
                                        parentInfo={userDetails.PetParentInfo}
                                        address={userDetails.PhysicalAddress}
                                        mailingAddress={userDetails.MailingAddress}
                                        reimbursement={userDetails.ClaimReimbursementMethod}
                                        policyEffectiveDate={userDetails.PolicyEffectiveDate}
                                        hidePassword={false}
                                        status ={userDetails.Status}
                                    />
                                }
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between col-md-4 mb-4 order-2">
                        <div className="content-wrapper">
                            <h2 className="text-primary-heading mb-0" > Billing Information </h2>
                                { userDetails && userDetails.BillingAddress &&
                                    <BillingInfo
                                        creditCard={userDetails.Last4CreditCard}
                                        billingInfo={userDetails.BillingAddress}
                                        status={userDetails.Status}
                                        premium={userDetails.MonthlyPremium}
                                        billingDay={userDetails.BillingDay}
                                        newMonthlyPremium={userDetails.NewMonthlyPremium}
                                        changeEffectiveDate={userDetails.ChangeEffectiveDate}
                                    />
                                }
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between col-md-4 mb-4 order-1 order-md-3" >
                        <div className="content-wrapper p-0">
                            <ViewClaimStatusInfo
                                claimsDetails={claimsDetails}
                            />
                        </div>
                    </div>

                    <div className="d-md-flex flex-column justify-content-start col-md-4 mb-4 d-none order-4" >
                        <div className="link-list">
                            <div className="list-title" >My Account</div>
                            <Link to={AppLinks.account.viewProfile}><div className="list-content" >View Your Profile <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                            { (userDetails.Status.toLowerCase().trim() === "cancel prorata" ||
                                userDetails.Status.toLowerCase().trim() === "cancel full") ? null :(<Link to={AppLinks.account.changePhysicalAddress}><div className="list-content" >Change Physical Address <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>)}
                            <Link to={AppLinks.account.changeProfile}><div className="list-content" >Change Profile <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                            <Link to={AppLinks.account.changePassword}><div className="list-content" >Change Password <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                        </div>
                    </div>
                    <div className="d-md-flex flex-column justify-content-start col-md-4 mb-4 d-none order-5" >
                        <div className="link-list">
                            <div className="list-title" >Pet Policies</div>
                            <Link to={AppLinks.policy.viewPetDetails}><div className="list-content" >View Pet Details <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                            { (userDetails.Status.toLowerCase().trim() === "cancel prorata" ||
                                userDetails.Status.toLowerCase().trim() === "cancel full") ? null :(<Link to={AppLinks.policy.changePolicyOptions}><div className="list-content" >View Policy Options <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>)}
                            <Link to={AppLinks.policy.viewPolicyDocuments}><div className="list-content" >View Policy Documents <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                        </div>
                    </div>
                    <div className="d-md-flex flex-column justify-content-start col-md-4 mb-4 d-none order-6" >
                        <div className="link-list">
                            <div className="list-title" >Frequently Asked Questions</div>
                            <Link to={AppLinks.faq.planCoverageFAQ}><div className="list-content" >Plan Coverage FAQs <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                            <Link to={AppLinks.faq.policyManagementFAQ}><div className="list-content" >Policy Management FAQs <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                            <Link to={AppLinks.faq.submittingClaimsFAQ}><div className="list-content" >Submit Claims FAQs <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                            {/* {
                                enableRAF && <Link  to={AppLinks.faq.referAFriendFAQ}><div className="list-content" >Refer a Friend FAQs <span className="d-md-block" > <i className="fa fa-angle-right fa-2x text-secondary " /> </span></div></Link>
                            }                             */}
                        </div>
                    </div>

                </div>
            </div>
            <LiveChat
                userAccountDetails={userDetails}
            />
        </div >
    )
}

export default Home;