import React from "react";
import { Link } from "react-router-dom";

import { AppLinks } from "../../../../constants/app-links";
import { dateSuffix } from "../../../common/util/util";
import "./BillingInfo.css";

const BillingInfo = ({ creditCard, billingInfo, billingDay, status, premium, newMonthlyPremium, changeEffectiveDate }) => {
    let mm = '00';
    let dd = '00';
    if (newMonthlyPremium) {
        const date = new Date(changeEffectiveDate);
        mm = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
        dd = (date.getDate() < 10 ? '0' : '') + (date.getDate());
    }

    return (
        <div className="billing-info" >
            <div className="info-header text-primary" >Billing Information</div>
            <div className="info-body d-flex flex-column justify-content-between" >
                <div className="item mb-3" >
                    <div className="d-flex justify-content-between align-items-center" >
                        <span className="subtitle" > Billing Information </span>
                        { (status.toLowerCase().trim() === "cancel prorata" ||
                            status.toLowerCase().trim() === "cancel full") ? null :<Link to={AppLinks.account.updateBillingInfo}><button className="text-secondary" >Edit</button></Link>}
                    </div>
                    <span>Card ending in {creditCard} </span> <br />
                    <span> {billingInfo.Name} </span> <br />
                    <span> {billingInfo.Street} </span> <br />
                    <span> {billingInfo.City}, {billingInfo.State} {billingInfo.PostalCode} </span>
                </div>
                <div className="item mb-3" >
                    <span className="subtitle" > Billing Status </span> <br />
                    {status === "Active" || status === "Transfer in Progress" ? <span className="text-primary"> Current </span>
                        : status === "Postpone Payment" ? <span className="text-danger"> Postponed Payment </span>
                            : status === "Suspended" ? <span className="text-danger">Suspended</span>
                            : (status.toLowerCase().trim() === "cancel prorata" ||
                                status.toLowerCase().trim() === "cancel full") ? <span className="text-danger">Pending Cancel</span>
                                : <span className="text-danger">Past Due</span>
                        //Note: extra information displayed as inline alert boxes in existing rcc are shown in the general alerts.
                    }
                </div>
                <div className="item mb-3" >
                    <span className="subtitle" >
                        {newMonthlyPremium
                            ? "Current"
                            : ""
                        } Monthly Premium 
                    </span> <br />
                    <span> {premium} (on the {dateSuffix(billingDay)} of each month)</span>
                </div>
                { newMonthlyPremium &&
                    <div className="item">
                        <span className="subtitle text-primary" >New Monthly Premium</span>
                        <div className="font-weight-bold"> <span className="text-primary"> ${Number(newMonthlyPremium).toFixed(2)} </span> <i>({mm + "/" + dd})</i></div>
                    </div>}

            </div>
            <div className="info-footer" ></div>
        </div>
    );
}

export default BillingInfo;