import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import HeaderTop from "./components/HeaderTop";
import Messages from "./../messages/Messages";
import { getMessagesByAccount } from "./../../../store/actions/messages-action";
import { getAccountInfo } from "./../../../store/actions/accounts-actions";
import MessageModal from "./../messages/MessageModal";
import RetryBilling from "./../../account/retry-billing/RetryBilling";

import MobileHeader from "./components/mobile-header/MobileHeader";
import DropdownMenu from "./components/dropdown-nav-menu/DropdownMenu";
import { logOut } from "../../../store/actions/login-actions";
import { AppLinks } from "../../../constants/app-links";

import Popover from "./../animation-popover/Popover";

import "./Header.css";

class Header extends Component {
  constructor(props) {
    super(props);
    //Initalizing Variables
    this.currentToggleMenu = "";
    this.toggleParent = null;
    this.toggleMenuItems = null;
    this.state = {
      innerWidth: 0,
      screenSize: "md",
      showToggleMenu: false,
      toggleMenuItem: null,
      isMessageModalOpen: false,
      xPosition: 0
    };
    this.selectedMessage = {};
    this.menuItemClicked = false;
    this.retryBillingRef = React.createRef();
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.updatePositions);
    this.props.actions.getAccountInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updatePositions);
  }

  retryBillingHandler = () => {
    if (this._isMounted) {
      this.setState({
        isMessageModalOpen: !this.state.isMessageModalOpen
      });
    }
    this.retryBillingRef.current.retryBillingHandler();

  };

  updatePositions = () => {
    if (this._isMounted) {
      this.setState({ xPosition: document.body.getBoundingClientRect().left });
    }
  };

  handleOutsideClick = evt => {
    if (this.state.showToggleMenu && this.currentToggleMenu !== "") {
      this.setToggleMenuFalse();
      document.removeEventListener("click", this.handleOutsideClick);
    }
  };

  setSelectedMessage = message => {
    this.selectedMessage = message;
    this.toggleMessageModal();
  };

  toggleMessageModal = () => {
    if (this._isMounted) {
      this.setState({
        isMessageModalOpen: !this.state.isMessageModalOpen
      });
    }
  };

  setMenuItems = menuName => {
    switch (menuName) {
      case "need-help":
        this.currentToggleMenu = "need-help";
        this.toggleMenuItems = this.needHelpMenu();
        this.position = "center";
        this.popoverWidth = 250;
        break;
      case "my-account":
        this.currentToggleMenu = "my-account";
        this.position = "center";
        this.toggleMenuItems = this.myaccountMenu();
        this.popoverWidth = 170;
        break;
      case "messages":
        this.currentToggleMenu = "messages";
        this.position = "center";
        this.toggleMenuItems = this.messageMenu();
        this.popoverWidth = 300;
        break;
      default:
        this.currentToggleMenu = "";
        this.toggleMenuItems = null;
        break;
    }
    if (this._isMounted) {
      this.setState({
        showToggleMenu: true
      });
    }
  };

  toggleMenu = (evt, menuName) => {
    this.menuItemClicked = true;
    const parentImage = evt.currentTarget;

    if (this.currentToggleMenu === menuName) {
      this.handleOutsideClick();
      this.setToggleMenuFalse();
      return;
    }

    if (menuName === "messages" && this.props.messagesFetchError)
      this.props.actions.getMessagesByAccount();

    if (!this.state.showToggleMenu) {
      document.addEventListener("click", this.handleOutsideClick.bind(this));
    }

    if (this.currentToggleMenu !== "") {
      //sets the popover to false if it shows
      if (this._isMounted) {
        this.setState({
          showToggleMenu: false
        });
      }
      //sets the popover style to empty object , so that animation comes from top
      setTimeout(() => {
        this.toggleParent = null;
        if (this._isMounted) {
          this.setState({
            showToggleMenu: false
          });
        }
      }, 250);

      //Sets popover to show
      setTimeout(() => {
        this.toggleParent = parentImage;
        this.setMenuItems(menuName);
      }, 300);
    } else {
      this.toggleParent = parentImage;
      this.setMenuItems(menuName);
    }
    evt.nativeEvent.stopImmediatePropagation();
  };

  needHelpMenu() {
    const style = {
      width: 250,
      backgroundColor: "white",
      borderRadius: "5px"
    };
    return (
      <div style={style}>
        <ul>
          <li className="menu-item">
            <Link to={AppLinks.faq.faq}>Frequently Asked Questions</Link>
          </li>
          <li className="menu-item">
            <Link to={AppLinks.faq.faq + "?qid=file-claim"}>
              How do I file a claim?
            </Link>
          </li>
          <li className="menu-item">
            <Link to={AppLinks.faq.faq + "?qid=What_is_covered"}>
              What is covered?
            </Link>
          </li>
          <li className="menu-item">
            <Link to={AppLinks.faq.faq + "?qid=What_is_not_covered"}>
              What is not covered?
            </Link>
          </li>
          <li className="menu-item">
            <Link to={AppLinks.others.contact}>Contact Customer Care</Link>
          </li>
        </ul>
      </div>
    );
  }

  messageMenu(currentPageNo) {
    return (
      <Messages
        showMessages={this.state.showToggleMenu}
        setToggleMenuFalse={this.setToggleMenuFalse}
        setSelectedMessage={this.setSelectedMessage}
      />
    );
  }

  myaccountMenu() {
    const style = {
      width: 200,
      backgroundColor: "white",
      borderRadius: "5px"
    };
    return (
      <div style={style}>
        <ul>
          <li className="menu-item">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => this.props.actions.logOut()}
            >
              Sign Out
            </span>
          </li>
          <li className="menu-item">
            <Link to={AppLinks.account.viewProfile}>View your Profile</Link>
          </li>
          {(this.props.userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
            this.props.userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? null : (<li className="menu-item">
            <Link to={AppLinks.account.changePhysicalAddress}>
              Change Physical Address
            </Link>
          </li>)}
          {(this.props.userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
            this.props.userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? null : (<li className="menu-item">
            <Link to={AppLinks.account.updateBillingInfo}>
              Update Billing Info
            </Link>
          </li>)}
          {(this.props.userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
            this.props.userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? null : (<li className="menu-item">
            <Link to={AppLinks.account.changeProfile}>Change Profile</Link>
          </li>)}
          <li className="menu-item">
            <Link to={AppLinks.account.changePassword}>Change Password</Link>
          </li>
          <li className="menu-item">
            <Link to={AppLinks.claims.fileClaim}>
              <div
                id="file-claim-menu"
                className="d-flex align-items-center"
              >
                File a Claim
              </div>
            </Link>
          </li>
        </ul>
      </div>
    );
  }

  setToggleMenuFalse = () => {
    this.currentToggleMenu = "";
    if (this._isMounted) {
      this.setState({ showToggleMenu: false });
    }
    //sets the popover style to empty object , so that animation comes from top
    setTimeout(() => {
      this.toggleParent = null;
      if (this._isMounted) {
        this.setState({
          showToggleMenu: false
        });
      }
    }, 250);
  };

  render() {
    return (
      <div id="header-group" className="container-fluid">
        <RetryBilling ref={this.retryBillingRef} />
        <MessageModal
          selectedMessage={this.selectedMessage}
          toggleMessageModal={this.toggleMessageModal}
          isMessageModalOpen={this.state.isMessageModalOpen}
          retryBillingHandler={this.retryBillingHandler}
        />
        <div className="d-none d-lg-block">
          <Popover
            width={this.popoverWidth}
            position={this.position}
            parent={this.toggleParent}
            menuItems={this.toggleMenuItems}
            innerWidth={this.state.innerWidth}
            show={this.state.showToggleMenu}
            currentPageNo={this.state.currentPageNo}
          />
        </div>

        <div className="d-none d-lg-block d-xl-block">
          {/* <HeaderTop
            toggleMenu={this.toggleMenu.bind(this)}
            userName={this.state.userName}
            unreadMessageCount={this.props.unreadMessageCount}
          /> */}
          <DropdownMenu enableRAF={this.props.enableRAF} userAccountDetails={this.props.userAccountDetails}  toggleMenu={this.toggleMenu.bind(this)}
            userName={this.state.userName}
            unreadMessageCount={this.props.unreadMessageCount}/>
        </div>
        <div className="d-lg-none d-xl-none">
          <MobileHeader unreadMessageCount={this.props.unreadMessageCount} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  unreadMessageCount: state.messages.unreadMessagesCount,
  messagesFetchError: state.messages.messagesFetchError,
  enableRAF:state.account.enableRAF,
  userAccountDetails: state.account.userAccountDetails
});

//Wrap all the actions with dispatcher
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getMessagesByAccount, logOut, getAccountInfo }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
