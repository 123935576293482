import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAccountInfo } from "../../store/actions/accounts-actions";
import { getClaimsByAccount } from "../../store/actions/claims-actions";
import { getPetsByAccount } from "./../../store/actions/pets-actions";
import Home from "./Home";
import ImageCarousel from "./image-carousel/ImageCarousel";
import HomeBanner from "./banner/HomeBanner";
import SpeedyClaims from "./speedy-claims/SpeedyClaims";
import { Link } from "react-router-dom";
import Loader from "./../common/loader/Loader";
import "./HomePage.css";
import { AppLinks } from "../../constants/app-links";
import injectExtole from "../common/extole-hoc/extole-hoc";

const list = [
  {
    header: "Claims",
    submenu: [
      {
        title: "File a Claim",
        src: "/assets/images/icon-file-claim.svg",
        link: AppLinks.claims.fileClaim
      },
      {
        title: "View Claim Status",
        src: "/assets/images/icon-view-claim.svg",
        link: AppLinks.claims.claimStatus
      },
      {
        title: "Upload Medical Records",
        src: "/assets/images/icon-upload-mr.svg",
        link: AppLinks.claims.uploadMedicalRecords
      },
      {
        title: "Get the mobile App",
        src: "/assets/images/icon_mobile_app.svg",
        link: AppLinks.claims.getMobileApp
      }
    ]
  },
  {
    header: "My Account",
    submenu: [
      {
        title: "View Your Profile",
        i: "fa-user-circle",
        link: AppLinks.account.viewProfile
      },
      {
        title: "Update Billing",
        i: "fa-credit-card",
        link: AppLinks.account.updateBillingInfo
      },
      {
        title: "Claim Reimbursement Info",
        i: "fa-money",
        link: AppLinks.account.updateReimbursement
      },
      { 
        title: "Change Physical Address", 
        i: "fa-home",
        link: AppLinks.account.changePhysicalAddress 
      },
      { 
        title: "Change Mailing Address", 
        i: "fa-home",
        link: AppLinks.account.updateMailingAddress 
      },
      { 
        title: "Change Profile", 
        i: "fa-address-card" ,
        link: AppLinks.account.changeProfile
      },
      {
        title: "Change Password",
        i: "fa-lock",
        link: AppLinks.account.changePassword
      }
    ]
  },
  {
    header: "Pet Policy",
    submenu: [
      {
        title: "View Pet Details",
        src: "/assets/images/icon_view_pet_detail.svg",
        link: AppLinks.policy.viewPetDetails
      },
      {
        title: "Add a Pet",
        src: "/assets/images/icon-add-a-pet.svg",
        link: AppLinks.policy.addPet
      },
      {
        title: "View Policy Options",
        src: "/assets/images/icon_change_policy.svg",
        link: AppLinks.policy.changePolicyOptions
      },
      {
        title: "View Policy Documents",
        src: "/assets/images/icon_view_policy.svg",
        link: AppLinks.policy.viewPolicyDocuments
      }
    ]
  },
  // {
  //   id: "extole-mobile-refer",
  //   header: "Refer a Friend",
  //   submenu: [{ title: "Refer a Friend", src: "/assets/images/icon_raf.svg" }]
  // },
  {
    header: "Support",
    submenu: [
      { 
        title: "Frequent Questions", 
        i: "fa-question-circle",
        link: AppLinks.faq.faq 
      },
      { 
        title: "Contact Us", 
        src: "/assets/images/icon_contact_us.svg" ,
        link: AppLinks.others.contact
      }
    ]
  }
];
const cancelList = [
  {
    header: "Claims",
    submenu: [
      {
        title: "File a Claim",
        src: "/assets/images/icon_file_claim.svg",
        link: AppLinks.claims.fileClaim
      },
      {
        title: "View Claim Status",
        src: "/assets/images/icon_view_claim.svg",
        link: AppLinks.claims.claimStatus
      },
      {
        title: "Upload Medical Records",
        src: "/assets/images/icon_record_upload.svg",
        link: AppLinks.claims.uploadMedicalRecords
      },
      {
        title: "Get the mobile App",
        src: "/assets/images/icon_mobile_app.svg",
        link: AppLinks.claims.getMobileApp
      }
    ]
  },
  {
    header: "My Account",
    submenu: [
      {
        title: "View Your Profile",
        i: "fa-user-circle",
        link: AppLinks.account.viewProfile
      },
      {
        title: "Claim Reimbursement Info",
        i: "fa-money",
        link: AppLinks.account.updateReimbursement
      },
      {
        title: "Change Password",
        i: "fa-lock",
        link: AppLinks.account.changePassword
      }
    ]
  },
  {
    header: "Pet Policy",
    submenu: [
      {
        title: "View Pet Details",
        src: "/assets/images/icon_view_pet_detail.svg",
        link: AppLinks.policy.viewPetDetails
      },
      {
        title: "Add a Pet",
        i: "fa-plus-circle",
        link: AppLinks.policy.addPet
      },
      {
        title: "View Policy Documents",
        src: "/assets/images/icon_view_policy.svg",
        link: AppLinks.policy.viewPolicyDocuments
      }
    ]
  },
  // {
  //   id: "extole-mobile-refer",
  //   header: "Refer a Friend",
  //   submenu: [{ title: "Refer a Friend", src: "/assets/images/icon_raf.svg" }]
  // },
  {
    header: "Support",
    submenu: [
      { 
        title: "Frequent Questions", 
        i: "fa-question-circle",
        link: AppLinks.faq.faq 
      },
      { 
        title: "Contact Us", 
        src: "/assets/images/icon_contact_us.svg" ,
        link: AppLinks.others.contact
      }
    ]
  }
];

class HomePage extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
    this.props.actions.getAccountInfo();
    this.props.actions.getClaimsByAccount();
    this.props.actions.getPetsByAccount();
  }

  render() {
    const today = new Date();
    const memberSince = new Date(this.props.userAccountDetails.MemberSince);
    return (this.props.userAccountDetails && !this.props.fetchingAlerts && !this.props.fetchingClaims && 
      this.props.claimsDetails != null
      ) ? (
      <React.Fragment>
        {Math.round((today - memberSince) / (1000 * 60 * 60 * 24)) <= 15  ? (this.props.vetClinicPets == null? <HomeBanner></HomeBanner> : (this.props.claimsDetails.length >0 ? <ImageCarousel history={this.props.history} accountStatus={this.props.userAccountDetails.Status} /> : <SpeedyClaims></SpeedyClaims>  ))
      : (this.props.claimsDetails.length > 0 ? <ImageCarousel history={this.props.history} accountStatus={this.props.userAccountDetails.Status} /> : <SpeedyClaims></SpeedyClaims>  )  
      }
 
        {/*<MobileHome enableRAF={this.props.enableRAF} userAccountDetails={this.props.userAccountDetails}></MobileHome>*/}
        <Home
          className="d-md-block"
          userDetails={this.props.userAccountDetails}
          claimsDetails={this.props.claimsDetails}
          enableRAF={this.props.enableRAF}
        />
      </React.Fragment>
    ) : (
      <div style={{ height: 300 }}>
        <Loader />
      </div>
    );
  }
}


const MobileHome = (props) => {
  const FinalList= props.userAccountDetails.Status=="Cancel Prorata"? cancelList : list;
  return (
        <div className="d-flex d-md-none flex-column mobile-home">
          {FinalList.map(({ header, submenu, id  }, index) => (
            <React.Fragment key={index}>
              <div key={header} className={`header ${header === "Refer a Friend" && !props.enableRAF ? "d-none" : ""}`}>
                {" "}
                {header}{" "}
              </div>
              {submenu.map(({ title, i, src, link }) => (
                <Link key={title} to={link || "#"} className={header === "Refer a Friend" && !props.enableRAF ? "d-none" : ""}>
                  <div className="list" id={id}>
                    {i && (
                      <div className="p-0 text-center col-1">
                        {" "}
                        <i
                          className={"text-primary list-fa-icons fa " + i}
                        />{" "}
                      </div>
                    )}
                    {src && (
                      <div className="p-0 text-center col-1">
                        {" "}
                        <img
                          className="list-image"
                          src={src}
                          alt={title}
                        />{" "}
                      </div>
                    )}
                    <div className="list-title col-10"> {title} </div>
                    <div>
                      {" "}
                      <i className="fa fa-angle-right list-fa-arrow text-secondary " />{" "}
                    </div>
                  </div>
                </Link>
              ))}
            </React.Fragment>
          ))}
                    
        </div>
  );
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails,
  fetchingAlerts: state.alerts.fetchingAlerts,
  fetchingClaims: state.claims.fetchingClaims,
  enableRAF: state.account.enableRAF,
  claimsDetails: state.claims.claimsDetails,
  petDetails:state.pets.petsDetails,
  vetClinicPets:((state.pets.petsDetails.filter(pet => pet.HasVetclinic ).length !=0) ? state.pets.petsDetails.filter(pet => pet.HasVetclinic ) : null)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getAccountInfo,getClaimsByAccount,getPetsByAccount}, dispatch)
});


// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(HomePage);



export default injectExtole(injectExtole(connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage),{ctaID: "extole-mobile-refer"}), {ctaID: "extole-home-refer"});

/* Note, Injecting extole-home-refer not in Home componenet because Home is functional component and when props changes and rerendered, 
the script is injected twiece sometimes, causing UI to display 2 extole widgets whenever id element is tapped.
*/