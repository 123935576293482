import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { AppLinks } from "../../constants/app-links";
import { updateVetClinic } from "../../api/pets-api";
import ErrorView from "../common/error-view/ErrorView";
import AvatarEditor from "../common/avatar-editor/AvatarEditor";
import "./PetDetails.css";
import {
  Header,
  PetDetailListItem,
  PetDetailListItemCancelStatus,
  WaitingPeriod,
  AddAPetButton
} from "./ViewPetDetail-Components";
import { getAccountInfo } from "./../../store/actions/accounts-actions";
import { getPetsByAccount, setPetsDataRefresh } from "./../../store/actions/pets-actions";
import Loader from "../common/loader/Loader";
import BillingInfo from "../claims/components/billing-info/BillingInfo";
import PetParentInfo from "../view-profile/components/PetParentInfo";
import { getProtectedSinceDate } from "../common/util/util";
import { getProtectedSinceDateV2 } from "../common/util/util";
class PetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openItem: 0,
      petDetails: undefined,
      skipEnabled:false,
      showSubmitLoader:false,
      successView:false,
      showForm:false,
      AddFormLink:false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitVetClinic = this.submitVetClinic.bind(this);
    this.skipToday = this.skipToday.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.toggletoForm = this.toggletoForm.bind(this);
  }

  componentDidMount() {
    this.props.actions.getAccountInfo();
    const locationState = this.props.location.state != null ? this.props.location.state.showForm : false;
    this.setState({
      showForm:locationState
    });
    if(this.props.location.state != null && this.props.location.state.showForm==true)
    {
      localStorage.removeItem("skipToday");
    }
    this.props.actions.getPetsByAccount();
    
    this.state.skipEnabled = localStorage.getItem("skipToday") != undefined ? Math.round((new Date() - new Date(localStorage.getItem("skipToday"))) / (1000 * 60 * 60 * 24)) >= 1 ? true : false : true ;
 

    
    
    if (this.props.location.state && this.props.location.state.petId) {
      const petId = this.props.location.state.petId;
      if (this.props.petDetails) {
        var openItemIndex = this.props.petDetails.findIndex(
          pet => pet.PetID === petId
        );
        this.setState({ openItem: openItemIndex }, () => {
          const openItem = document.getElementById("pet-accordion-"+openItemIndex);
          if(openItem) {
            openItem.scrollIntoView();

            if (window.innerWidth <= 960) 
              window.scrollBy(0,-80)
          }
        });
      }
    }
    else window.scrollTo(0, 0);
  }

  componentWillMount() {
    if (this.props.location.state && this.props.location.state.petId) {
      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {}
      });
    }
  }

  toggle = (e, item) => {
    this.setState({ openItem: item });
  };

  stateVerification = (state) => {
    if (state === "MD")
      return true;
    return false;
  };


  handleChange (evt) {
    if(evt.target.value != "")
    {
      document.querySelector("#"+evt.target.name).classList.add('filled');
    }
    else{
      document.querySelector("#"+evt.target.name).classList.remove('filled');
    }
    if(document.querySelector("#clinicName").value != "" && document.querySelector("#clinicCity").value != "")
    {
      document.querySelector("#submit").classList.add("enable");
    }
    else{
      document.querySelector("#submit").classList.remove("enable");
    }
  };
  submitVetClinic(e){
    
    e.preventDefault();
    this.setState({
      showSubmitLoader: true,
    });
    const parentId = (e.target.parentNode.getAttribute("id"));
    const data = new FormData(e.target);

    // Do a bit of work to convert the entries to a plain JS object
    const value = Object.fromEntries(data.entries());
    const PetId = value.PetId;
    delete value["PetId"];
    updateVetClinic(PetId,JSON.stringify(value)).then(response => {

      this.props.actions.setPetsDataRefresh();
      this.props.actions.getPetsByAccount();

      this.setState({
        successView : true,
      });
      this.setState({
        showSubmitLoader: false,
      });
    })
    .catch(error => {
      this.setState({
        
        showSubmitLoader: false,
      });
      //document.querySelector("#"+parentId).classList.add("d-none");
      document.querySelector("#failureMessage").classList.remove("d-none");
      document.querySelector("#failureMessage").classList.remove("d-block");

      this.errorString = error.errorMessage ? error.errorMessage : "Unable to update Vet Clinic Info. Please try later.";
      this.setState({
        showError: true,
        showErrorWithContactUs: false,
        fetching: false
      });
    });;
  };

  skipToday(e){
    e.preventDefault();
    localStorage.setItem("skipToday", new Date().toString());
    this.setState({
      skipEnabled: false,
    });
  }

  toggleForm(e){
    e.preventDefault();
    this.setState({
      successView:false
    });
    var elems = document.querySelectorAll(".filled");
    [].forEach.call(elems, function(el) {
      el.classList.remove("filled");
    });
    
  }

  toggleSuccess(e){
    e.preventDefault();
    
    var elems = document.querySelectorAll(".filled");
    [].forEach.call(elems, function(el) {
      el.classList.remove("filled");
    });
    this.setState({
      skipEnabled:false,
    });
    
  }

  toggletoForm(e){
    e.preventDefault();

    
    this.setState({
      skipEnabled:true,
    });
    this.setState({
      successView:false,
    });
    this.setState({
      AddFormLink:true,
    });
  }
  
  render() {
    return (
      <div className="container pet-detail-container">
        <div className="row">
          <Header className="col-12 col-lg-8 col-xs-12" />
        </div>
        <div className="row">
          <div className="col-lg-8 col-xs-12">
            {this.props.petDetails &&
            this.props.petDetails.length > 0 &&
            this.props.userAccountDetails && 
            !this.props.fetchingPets && 
            !this.props.fetchingAccount ? (
              <div id="accordion_container" className="accordion">
                {this.generateAccordian()}
              </div>
            ) : this.props.fetchingPets || this.props.fetchingAccount ? (
              <Loader />
            ) : this.props.error ? (
              <ErrorView>
                {this.props.error.message
                  ? this.props.error.message
                  : "Unable to fetch pet details. Please try later."}
              </ErrorView>
            ) : (
              <div>
                {" "}
                No pets available to show you right now. Please try later.{" "}
              </div>
            )}
            <AddAPetButton className="d-block d-md-none add-pet-button-mobile">
              Add a Pet+
            </AddAPetButton>
          </div>

          <div className="right-column col-lg-4 d-none d-lg-flex flex-column">
            {this.props.userAccountDetails ? (
              <React.Fragment>
                <div className="billing-info">
                  <div className="info-header text-primary">
                    Contact Information
                  </div>
                  <div className="info-body info-body-padding d-flex flex-column justify-content-between">
                    <PetParentInfo
                      parentInfo={this.props.userAccountDetails.PetParentInfo}
                      address={this.props.userAccountDetails.PhysicalAddress}
                      mailingAddress={this.props.userAccountDetails.MailingAddress}
                      policyEffectiveDate={this.props.userAccountDetails.PolicyEffectiveDate}
                      hidePassword={true}
                      status={this.props.userAccountDetails.Status}
                    />
                  </div>
                  <div className="info-footer" />
                </div>

                <BillingInfo
                  creditCard={this.props.userAccountDetails.Last4CreditCard}
                  billingInfo={this.props.userAccountDetails.BillingAddress}
                  status={this.props.userAccountDetails.Status}
                  billingDay={this.props.userAccountDetails.BillingDay}
                  premium={this.props.userAccountDetails.MonthlyPremium}
                  newMonthlyPremium={
                    this.props.userAccountDetails.NewMonthlyPremium
                  }
                  changeEffectiveDate={this.props.userAccountDetails.ChangeEffectiveDate}
                />
              </React.Fragment>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    );
  }

  generateAccordian = () => {
    return this.props.petDetails.map((petDetail, index) => {
      return (
        <div
          id={"pet-accordion-"+index}
          key={index}
          className="accordion_sub"
          style={{ borderRadius: index === 0 ? "8px" : "0px" }}
        >
          <div onClick={e => this.toggle(e, index)}>
            <div
              className={
                (this.state.openItem === index
                  ? "d-none d-md-flex accordian-title-start "
                  : "d-flex ") +
                "accordian-title justify-content-center justify-content-md-start align-items-center d-md-block"
              }
            >
              <span  className="truncate text-capitalize">
                {petDetail.Name}
              </span>
              <span className="d-none d-md-inline accordian-sub-title">
                &nbsp;&nbsp;
                {petDetail.Age === 0
                  ? petDetail.Type === "Dog"
                    ? "Puppy"
                    : "Kitten"
                  : "Age " + petDetail.Age}
                , {petDetail.Breed}
              </span>
            </div>
            {this.state.openItem !== index && (
              <div className="show-view d-flex align-items-center">
                <span className="d-none d-md-flex flex-align-items-center">
                  View Pet Details
                </span>
                <i className="fa  fa-angle-down show-icon" />
              </div>
            )}
          </div>
          {this.state.openItem === index && (
            
            
            <React.Fragment>
            <div className={(this.state.skipEnabled && (this.state.showForm || this.state.AddFormLink ) ?  "d-flex " : "d-none ") +
            "flex-column flex-md-row accordian-container addVetClinic"}>
              {(this.state.successView == false ?
              <React.Fragment>
              <div className="form-content col-md-12" id={"Pet" +index +"container"}>
                    <p className="description">Please enter the contact information of your vet clinic.</p>
                    <p className="required">* Required information</p>
                    <p className="d-none failureMessage" id="failureMessage">Unable to update Vet ClinicInfo, Please try later.</p>
                    
                    <form className="form-container" id="formContainer" onSubmit={this.submitVetClinic}>
                      <input type="hidden" value={petDetail.PetID} name="PetId"/>
                    <div className="mb-3 form-group">
                      
                      <input onChange={this.handleChange} type="text" className="form-control" id="clinicName" name="clinicName" placeholder=""/>
                      <label htmlFor="clinicName" className="form-label">Vet Clinic Name*</label>
                    </div>
                    <div className="mb-3 form-group">
                      
                      <input onChange={this.handleChange} type="text" className="form-control" id="clinicCity" name="clinicCity" placeholder=""/>
                      <label htmlFor="clinicCity" className="form-label">City*</label>
                    </div>
                    <div className="mb-3 form-group">
                      
                      <input onChange={this.handleChange} type="text" className="form-control" id="clinicStreetAddress" name="clinicStreetAddress" placeholder=""/>
                      <label htmlFor="clinicStreetAddress" className="form-label">Street Address (optional)</label>
                    </div>
                    <div className="mb-3 form-group width50">
                      
                      <select onChange={this.handleChange} id="clinicState" name="clinicState" className="form-control">
                        <option value="">Select State(optional)</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                    <div className="mb-3 form-group width50">
                      
                      <input onChange={this.handleChange} type="tel" className="form-control" id="clinicPhone" name="clinicPhone" placeholder=""/>
                      <label htmlFor="clinicPhone" className="form-label">Phone Number (optional)</label>
                    </div>
                    <div className="md-3 form-group">
                      <input type="submit" className="form-control submit" id="submit" value="Submit" />
                        
                      <button className="form-control skip" onClick={this.skipToday}>
                        Skip Today
                      </button>
                    </div>
                    </form>
                    {this.state.showSubmitLoader && (
                    <Loader position="absolute" fullPage={true} />
                  )}
                </div> </React.Fragment>
                :
                <React.Fragment>
              <div className="col-md-12 successMessage" id="successMessage">
                <p>Success! {petDetail.Name}'s vet clinic info has been successfully added.</p>
                <button className="done" onClick={this.toggleSuccess}>Done</button>
                <button onClick={this.toggleForm} className="addMore">Add More Vet/Clinic</button>
              </div> </React.Fragment>) }
            </div>
          
              <div className={( this.state.skipEnabled && (this.state.showForm || this.state.AddFormLink )? "d-none " : "d-flex ")  +
              "flex-column flex-md-row accordian-container petDetails"}>
                <div className="col-12 col-md-4 d-flex flex-column justify-content-center align-items-center justify-content-md-start">
                  <AvatarEditor
                    petDetails={petDetail}
                    image={
                      petDetail.ImageAvailable ? petDetail.PetImageURL : null
                    }
                    defaultImage={
                      petDetail.Type === "Cat"
                        ? "../../assets/images/add-cat.png"
                        : "../../assets/images/add-dog.png"
                    }
                  />
                  <span
                    className="accordian-mobile-name truncate d-md-none"
                    style={{ maxWidth: 250 }}
                  >
                    {petDetail.Name}
                  </span>
                </div>
                <PetDetaillayout
                  petDetail={petDetail}
                  userAccountDetails={this.props.userAccountDetails}
                  stateVerification={this.stateVerification(this.props.userAccountDetails.PhysicalAddress.State)}
                  toggleToForm={this.toggletoForm}
                />
              </div>
              <div className={(this.state.skipEnabled && (this.state.showForm || this.state.AddFormLink ) ? "d-none " : "d-md-flex ") +
              "flex-row-reverse align-items-end accordian_button_container"}>
                <Link
                  to={{
                    pathname: AppLinks.claims.fileClaim,
                    state: { PetID: petDetail.PetID }
                  }}
                  className="submit-a-claim-buttom"
                >
                  <button
                    id="submit_a_claim_button"
                    className="button-primary-plus"
                  >
                    Submit a Claim
                  </button>
                </Link>
                <Link
                  to={{
                    pathname: AppLinks.claims.uploadMedicalRecords,
                    state: { PetID: petDetail.PetID }
                  }}
                  className="upload_medical_record_button"
                >
                  <button
                    id="upload_medical_record_button"
                    className="button-secondary"
                  >
                    Upload Medical Records
                  </button>
                </Link>
              </div>
              {/* <Link
                to={{
                  pathname: AppLinks.claims.fileClaim,
                  state: { PetID: petDetail.PetID }
                }}
              >
                <div className="d-md-none submit-a-claim-buttom-mobile">
                  <button
                    id="submit_a_claim_buttom"
                    className="button-primary-plus"
                  >
                    Submit a Claim
                  </button>
                </div>
              </Link> */}
            </React.Fragment> 
          )}
        </div>
      );
    });
  };
}

const PetDetaillayout = props => {
  let protectedDate = undefined;
  if (
    !props.stateVerification
  ) {
      //protectedDate = props.petDetail.WaitingPeriodDate;
      protectedDate = getProtectedSinceDateV2(props.petDetail.WaitingPeriodDate);
  } else {
      //protectedDate = props.petDetail.WaitingPeriodDate;
      protectedDate = getProtectedSinceDateV2(props.petDetail.WaitingPeriodDate);
  }

  return (
    <div className="col-12 col-md-8 accordian_detail_section">
      <div className="d-flex flex-column container">
        <PetDetailListItem
          title="Pet ID:"
          value={props.petDetail.PetPolicyNo}
        />
        <PetDetailListItem
          title="Policy Number:"
          value={props.userAccountDetails.PolicyNumber}
        />
        {(props.userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
          props.userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? 
        <PetDetailListItemCancelStatus
          title="Status:" 
          value="Pending Cancel"
        />
        :null
        }
        {!props.stateVerification && (
            <PetDetailListItem
              title="Enrollment Date: "
              value={props.petDetail.EnrollmentStartDate}
            />
          )}
        <PetDetailListItem title="Protected Since:" value={protectedDate} />
        <PetDetailListItem
          title="Monthly Premium:"
          value={props.petDetail.MonthlyPremium}
        />
        <PetDetailListItem
          title="Reimbursement:"
          value={props.petDetail.Reimbursement}
        />
        <PetDetailListItem
          title="Annual Deductible:"
          value={props.petDetail.Deductible}
        />
        {parseFloat(props.petDetail.Deductible.replace("$", "")) !==
          parseFloat(
            props.petDetail.CurrentYearRemainingDeductible.replace("$", "")
          ) && (
          <React.Fragment>
            <PetDetailListItem
              className="d-none d-md-flex"
              title="Remain Annual Deductible: "
              value={props.petDetail.CurrentYearRemainingDeductible}
            />
            <PetDetailListItem
              className="d-flex d-md-none"
              title="Remain Deductible: "
              value={props.petDetail.CurrentYearRemainingDeductible}
            />
          </React.Fragment>
        )}
        {!props.petDetail.HasVetclinic ? 
        <React.Fragment>
          <PetDetailListItem
          title="Primary Vet Clinic"
          value={<button className='CTA' onClick={props.toggleToForm}>Add</button>}
        />
        </React.Fragment>:
        ""
        
    }
        
      </div>
      <WaitingPeriod
        name={props.petDetail.Name}
        age={props.petDetail.Age}
        protected_since={protectedDate}
        stateVerification={props.stateVerification}
        userAccountDetails={props.userAccountDetails}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails,
  fetchingAccount: state.account.fetchingAccount,
  error: state.pets.error,
  petDetails: ((state.pets.petsDetails.filter(pet => pet.Status =="Active").length !=0) ? state.pets.petsDetails.filter(pet => pet.Status === "Active") : state.pets.petsDetails),
  fetchingPets: state.pets.fetchingPets,
  vetClinicPets:((state.pets.petsDetails.filter(pet => pet.HasVetclinic ).length !=0) ? state.pets.petsDetails.filter(pet => pet.HasVetclinic ) : null)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAccountInfo,
      setPetsDataRefresh,
      getPetsByAccount
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetDetails);
