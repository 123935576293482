import React from "react";
import "./Footer.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppLinks } from "../../../constants/app-links";
import RAFBottomBanner from "../raf-bottom-banner/RAFBottomBanner";

const Address = () => {
  return (
    <React.Fragment>
      <div
        id="footer-address"
        className="address d-sm-none d-md-block col-lg-6 col-md-6"
      >
        <div>
          <div>
            <strong>Phone: </strong>
            <span className="mobile-hide">855.898.8991</span>
            <a className="mobile-show" href="tel:+18558988991" track="Ftr - 855.898.8991">855.898.8991</a>
          </div>
          <div>
            <strong>Fax: </strong>
            <span>888.228.4129</span>
          </div>
        </div>

        <div>
          <div>
            <strong>Address: </strong>
          </div>
          <span>Healthy Paws</span>
          <br />
          <span className="d-none d-md-block d-lg-block d-xl-block">
            <span>P.O. Box 50034 </span>
            <br />
            <span>Bellevue, WA 98015</span>
          </span>
          <span className="d-md-none">
            <span>P.O. Box 50034 | </span>
            <span>Bellevue, WA 98015</span>
          </span>
        </div>
      </div>

      <div
        id="footer-address"
        className="address address-row d-none d-sm-flex d-md-none d-md-none d-lg-none"
      >
        <div className="col-md-6">
          <div>
            <strong>Address: </strong>
          </div>
          <span>Healthy Paws</span>
          <br />
          <span>P.O. Box 50034 | </span>
          <span>Bellevue, WA 98015</span>
        </div>
        <div className="col-md-6">
          <div>
            <strong>Phone: </strong>
            <span>855.898.8991</span>
          </div>
          <div>
            <strong>Fax: </strong>
            <span>888.228.4129</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const SocialIcons = ({ loggedIn }) => {
  return (
    <div id="footer-connected-icons" className="col-lg-6 col-md-6 pr-0">
      <div>
        <span>
          <strong>Keep Connected</strong>
        </span>
      </div>
      <div>
        <a
          href={AppLinks.externalLinks.faceBook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/facebook.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/38x38_W_X@2x-8.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/instagram.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.pinterest}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/pintrest.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.youTube}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/youtube.png"
          />
        </a>
      </div>
    </div>
  );
};

const CommonInfo = () => {
  return (
      <div id="footer-info" className="footer-info">

          <p>
          <a className="ot-sdk-show-settings optanon-toggle-display USFooterLink"
          target="_blank"
          rel="noopener noreferrer" >Cookie Preferences</a>
          </p>

          
      <p>
      Plan coverage varies based on the age of the pet at enrollment and the deductible and reimbursement levels chosen at enrollment. Exclusions and restrictions apply. All descriptions or highlights of the insurance being provided are for general information purposes only, do not address state-specific notice or other requirements and do not amend, alter or modify the actual terms or conditions of an insurance policy. Please refer to the terms and conditions of the policy, which set forth the scope of insurance being provided and address relevant state requirements.
      </p>

      <p>
      Insurance offered by Healthy Paws, a Chubb Company, is underwritten by ACE
        American Insurance Company, Westchester Fire Insurance Company,
        Indemnity Insurance Company of North America, ACE Property and Casualty
        Insurance Company and Atlantic Employers Insurance Company and one or
        more of their U.S. based Chubb underwriting company affiliates. Chubb is the marketing name used to refer to subsidiaries of Chubb Limited providing insurance and related services. For a list of these subsidiaries, please visit our website at <a href="http://www.chubb.com/" target="_blank" >www.chubb.com.</a> These insurance products are offered through Chubb Insurance Solutions Agency, Inc. (California license no. 0D12120). Chubb, 202 Hall's Mill Road, Whitehouse Station, NJ 08889-1600.
      </p>
    </div>
  );
};


const LoggedInInfo = (props) => {
  const { userAccountDetails } = props;
  return (
      <div id="footer-info" className="footer-info">
      
          <p>
           <a 
                  className="ot-sdk-show-settings optanon-toggle-display USFooterLink"
           target="_blank"
           rel="noopener noreferrer">
           Cookie Preferences
          </a>
          </p>
          
      <p>
      Plan coverage varies based on the age of the pet at enrollment and the deductible and reimbursement levels chosen at enrollment. Exclusions and restrictions apply. All descriptions or highlights of the insurance being provided are for general information purposes only, do not address state-specific notice or other requirements and do not amend, alter or modify the actual terms or conditions of an insurance policy. Please refer to the terms and conditions of the policy, which set forth the scope of insurance being provided and address relevant state requirements.
      </p>

      <p>
        Insurance offered by Healthy Paws, a Chubb Company, is underwritten by { userAccountDetails.Property }, a member of Chubb. Chubb is the marketing name used to refer to subsidiaries of Chubb Limited providing insurance and related services. For a list of these subsidiaries, please visit our website at <a href="http://www.chubb.com/" target="_blank" >www.chubb.com.</a> These insurance products are offered through Chubb Insurance Solutions Agency, Inc. (California license no. 0D12120). Chubb, 202 Hall's Mill Road, Whitehouse Station, NJ 08889-1600.
      </p>
      
    </div>
  );
};

const FooterImages = () => {
  return (
    <div id="footer-images" className="d-none d-md-block d-lg-block d-xl-block">
        <img
          className="footer-image"
          alt="Contact information"
          src="../../assets/images/cybersource_logo.png"
          height="31px"
          width="134px"
        />
      <a
        href={AppLinks.externalLinks.bbb}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="footer-image"
          alt="Contact information"
          src="../../assets/images/accredited.png"
        />
      </a>

      <a
        href={AppLinks.externalLinks.naphia}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="footer-image"
          alt="Contact information"
          src="../../assets/images/naphia.png"
        />
      </a>
    </div>
  );
};

const PetPoliciesList = (props) => {
  const {userAccountDetails}=props;
  return (
    <div
      id="footer-petpolicies-list"
      className="col-xs-12 col-sm-6 col-md-6 col-lg-3"
    >
      <ul>
        <li>
          <strong>Pet Policies</strong>
        </li>
        <li>
          <Link to={AppLinks.policy.viewPetDetails}>View Pet Details</Link>
        </li>
        {(userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
          userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? null :<li>
          <Link to={AppLinks.policy.addPet}>Add a Pet</Link>
        </li>}
        {(userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
          userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? null :<li>
          <Link to={AppLinks.policy.changePolicyOptions}>
            View Policy Options
          </Link>
        </li>}
        <li>
          <Link to={AppLinks.policy.viewPolicyDocuments}>
            View Policy Documents
          </Link>
        </li>
      </ul>
    </div>
  );
};

const MyAccountList = (props) => {
  const {userAccountDetails}= props;
  return (
    <div
      id="footer-myaccount-list"
      className="col-xs-12 col-sm-6 col-md-6 col-lg-3"
    >
      <ul>
        <li>
          <strong>My Account</strong>
        </li>
        <li>
          <Link to={AppLinks.account.viewProfile}>View Your Profile</Link>
        </li>
          {(userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
            userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? null :<li><Link to={AppLinks.account.changePhysicalAddress}>
            Change Physical Address
          </Link>
        </li>}
        {(userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
          userAccountDetails.Status.toLowerCase().trim() === "cancel full") ? null :<li>
          <Link to={AppLinks.account.changeProfile}>Change Profile</Link>
        </li>}
        <li>
          <Link to={AppLinks.account.changePassword}>Change Password</Link>
        </li>
        {(userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
          userAccountDetails.Status.toLowerCase().trim() === "cancel full")? null :<li>
          <Link to={AppLinks.account.updateBillingInfo}>
            Update Billing Info
          </Link>
        </li>}
        {(userAccountDetails.Status.toLowerCase().trim() === "cancel prorata" ||
          userAccountDetails.Status.toLowerCase().trim() === "cancel full")? null :<li>
          <Link to={AppLinks.account.updateReimbursement}>
            Change Reimbursement Info
          </Link>
        </li>}
      </ul>
    </div>
  );
};

const ClaimsList = () => {
  return (
    <div
      id="footer-claims-list"
      className="col-xs-12 col-sm-6 col-md-6 col-lg-3"
    >
      <ul>
        <li>
          <strong>Claims</strong>
        </li>
        <li>
          <Link to={AppLinks.claims.fileClaim}>File a Claim</Link>
        </li>
        <li>
          <Link to={AppLinks.claims.claimStatus}>View Claim Status</Link>
        </li>
        <li>
          <Link to={AppLinks.claims.uploadMedicalRecords}>
            Upload Medical Records
          </Link>
        </li>
        <li>
          <Link to={AppLinks.claims.getMobileApp}>Get the Mobile App</Link>
        </li>
      </ul>
    </div>
  );
};

const SupportList = () => {
  return (
    <div id="footer-support-list" className="col-lg-3 col-md-4">
      <ul>
        <li>
          <strong>Support</strong>
        </li>
        <li>
          <a href={AppLinks.faq.faq} target="_blank" rel="noopener noreferrer">
            Frequent Questions
          </a>
        </li>
        <li>
          <a
            href={AppLinks.others.contact}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </a>
        </li>
        <li>
          <a
            href={AppLinks.externalLinks.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
              </li>
              <li>
                  <a
                      href={AppLinks.externalLinks.caPrivacyPolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                      CA Privacy Policy
                  </a>
              </li>
 
        <li>
          <a
            href={AppLinks.externalLinks.legal}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </li>
      </ul>
    </div>
  );
};

const SupportListCommon = () => {
  return (
    <div
      id="footer-support-list"
      className="d-none d-md-block d-lg-block d-xl-block col-md-3"
    >
      <ul>
        <li>
          <strong>Support</strong>
        </li>
        <li>
          <a
            href={AppLinks.externalLinks.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
         </li>
         <li>
                  <a
                      href={AppLinks.externalLinks.caPrivacyPolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                      CA Privacy Policy
                  </a>
         </li>
     
        <li>
          <a
            href={AppLinks.externalLinks.legal}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </li>
      </ul>
    </div>
  );
};

const SocialIconsCommon = () => {
  return (
    <div id="footer-connected-icons" className="col-sm-12 col-md-12">
      <div>
        <span>
          <strong>Keep Connected</strong>
        </span>
      </div>
      <div>
        <a
          href={AppLinks.externalLinks.faceBook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/facebook.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/38x38_W_X@2x-8.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/instagram.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.pinterest}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/pintrest.png"
          />
        </a>
        <a
          href={AppLinks.externalLinks.youTube}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Contact information"
            src="../../assets/images/youtube.png"
          />
        </a>
      </div>
    </div>
  );
};

const LoggedInFooter = (props) => {
  const {userAccountDetails}=props;
  return (
    <React.Fragment>
      <div className="d-none d-md-block d-lg-none col-lg-8 col-md-6">
        <div className="row">
          <PetPoliciesList userAccountDetails= {props.userAccountDetails} />
          <MyAccountList userAccountDetails= {props.userAccountDetails} />
        </div>
        <div className="row">
          <ClaimsList />
          <SupportList />
        </div>
      </div>

      <div className="d-none d-md-none d-lg-block col-lg-8 col-md-6">
        <div className="row">
          <PetPoliciesList userAccountDetails={props.userAccountDetails} />
          <MyAccountList userAccountDetails={props.userAccountDetails} />
          <ClaimsList />
          <SupportList />
        </div>
      </div>

      <div className="address-social-icons-wrapper row col-lg-4 col-md-6">
        <Address />
        <SocialIcons />
      </div>
    </React.Fragment>
  );
};

const CommonFooter = () => {
  return (
    <div className="d-flex flex-wrap justify-content-start">
      <div>
        <SupportListCommon />
      </div>
      <div className="mr-auto">
        <Address />
      </div>
      <div className="col-lg-2 col-xl-2 col-md-3 p-0">
        <SocialIconsCommon />
      </div>
    </div>
  );
};

const footer = (props) => {
  const { userAccountDetails, location, commonFooter, enableRAF } = props;
  return (
    <div
      id="footer-group"
      className={`footer-group container-fluid ${
        !commonFooter &&
        userAccountDetails &&
        userAccountDetails.RAFFriendlyUrl &&
        location.pathname === "/"
          ? "has-banner"
          : ""
      }`}
    >
      <div className="container">
        {props.commonFooter ? (
          <CommonFooter />
        ) : (
          <div className="row">
                          <LoggedInFooter userAccountDetails={props.userAccountDetails} />
           </div>
        )}
        <FooterImages />
        {props.commonFooter ? (
          <CommonInfo />
        ) : (
          <LoggedInInfo userAccountDetails={props.userAccountDetails} />
        )}

      </div>
      {/* {!commonFooter &&
      userAccountDetails &&
      enableRAF &&
      userAccountDetails.RAFFriendlyUrl &&
      location.pathname === "/" ? (
        <RAFBottomBanner friendlyURL={userAccountDetails.RAFFriendlyUrl} />
      ) : null} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userAccountDetails: state.account.userAccountDetails,
  enableRAF: state.account.enableRAF,
});

export default withRouter(connect(mapStateToProps)(footer));
